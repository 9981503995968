var LazyImages = ( function () {
  window.onload = function () {

    var VP = { height: window.innerHeight, width: window.innerWidth };
      // get all img tags with a [data-src] attribute
      // in ./inc/template-lazyload.php
      // we swap out all <img src> with <img data-src>
      // so no images are loaded before all other content
      // has maded it, and you don't load all only the ones
      // you're looking at.
      // 
    var images = getDataSrcImages();

    function getDataSrcImages() {
      return document.querySelectorAll( 'img[data-src]' );
    }


    function checkViewPortImages() {
	// Always fetch a new set of img with data-src
	// tag
      images = getDataSrcImages();
	// If we have images that hasn't been loaded yet
      if ( images.length > 0 ) {

	for(i=0;i<images.length;i++){
	    let img = images[i];

	    
	    // If the image is within the viewport, or half a viewport height
	    // underneath the fold, we load it in
	    if ( img.y <= window.scrollY + ( VP.height * 1.5 ) ) {
		img.src = img.dataset.src;
		img.removeAttribute( 'data-src' );
	    }
	}

      } else {
	// If the array is empty we remove the eventListener
	// and consider our selfs done.
        // console.log( 'All Images Loaded' );
        window.removeEventListener( 'scroll', checkViewPortImages );
      }
    }

    // Now that the script is loaded
    // lets run it once, so all images in
    // the fold and 1.5 fold down gets loaded
    // then attach it to the scroll event until we can't
    // find any more images.
    if ( images.length > 0 ) {
	checkViewPortImages();
	window.addEventListener( 'scroll', checkViewPortImages );
    }
  }
}() );
